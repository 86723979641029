import { useAssistant } from "./AssistantProvider";
import { AssistantIcon } from "./AssistantIcon/AssistantIcon";
import ChatPopup from "./ChatPopup/ChatPopup";
import ConversationHistory from "./ConversationHistory/ConversationHistory";

const AssistantWrapper = () => {
  const { isPopupOpen, setIsPopupOpen, conversations, selectedConversation } =
    useAssistant();

  const togglePopup = () => {
    setIsPopupOpen((prevState) => !prevState);
  };

  return (
    <>
      <AssistantIcon onClick={togglePopup} />
      <ConversationHistory />
      <ChatPopup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        initialMessages={conversations}
      />
    </>
  );
};

export default AssistantWrapper;
