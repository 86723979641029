export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
export const ASSISTANT_ID = process.env.REACT_APP_OPENAI_ASSISTANT_ID;
export const VECTOR_STORE_ID = process.env.REACT_APP_VECTOR_STORE_ID;
export const MODEL_ID = process.env.REACT_APP_MODEL_ID || "gpt-4o-2024-11-20"; // Update default model
export const TEMPERATURE = 0.5;
export const TOP_P = 1.0;


export const instructionToken = ` (Please find a relevant document(s) in your knowledge base and use that to answer me. Always provide specific examples and step by step exercises when appropriate. If you can't find answers in your knowledge-base, simply reply "I don't have information on that topic". Your output should be at least 4000 tokens.)`;

export const sampleQuestions = [
  "How can I improve my finger dexterity for fast passages, especially in pieces like Paganini Caprices?",
  "What are some effective exercises to develop a smooth and continuous vibrato?",
  "How can I achieve better bow distribution when playing long, sustained notes?",
  "What techniques can help me master spiccato and sautillé bow strokes?",
  "How can I improve my intonation when playing double stops, particularly in thirds and sixths?",
  "What are the best methods to practice shifting accurately between positions, especially in fast passages?",
  "How can I develop a more expressive and varied vibrato for different musical styles?",
  "What exercises can help me maintain evenness and control in my bowing, particularly during string crossings?",
  "How can I improve my left-hand finger independence and strength, especially for challenging passages?",
  "What are some advanced techniques for playing chords and arpeggios cleanly and in tune?",
  "How can I practice to achieve a more consistent and controlled martelé stroke?",
  "What are some strategies for improving my dynamic range and control on the violin?",
  "How can I develop better coordination between my left and right hands, especially in complex passages?",
  "What are some effective ways to practice and master ricochet bowing?",
  "How can I improve my ability to play fast, clean trills and ornaments?",
  "What exercises can help me develop a more fluid and connected legato bowing?",
  "How can I practice to achieve a more powerful and resonant sound on the G-string?",
  "What are some techniques for improving my bow control and articulation at the frog and tip?",
  "How can I practice to develop a more relaxed and efficient left-hand technique, especially for high positions?",
  "What are some advanced methods for practicing and mastering difficult passages in concertos and solo pieces?",
  "How can I effectively practice left-hand pizzicato to strengthen my fingers and improve my technique?",
  "What are some advanced exercises to develop a more controlled and expressive portamento?",
  'How can I use the "floating thumb" technique to reduce tension in my left hand while playing?',
  "What are the best methods to practice and master rapid string crossings in complex passages?",
  'How can I improve my bow control and articulation using the "Paganini Stroke"?',
  "What exercises can help me develop a more relaxed and efficient vibrato, especially in high positions?",
  "How can I practice to achieve a more powerful and resonant sound on the lower strings?",
  "What are some techniques for improving my bowing accuracy and evenness at the frog?",
  "How can I develop better coordination between my left and right hands using rhythmic variations?",
  "What are some effective ways to practice and master ricochet bowing for advanced repertoire?",
  "How can I improve my ability to play fast, clean double stops, particularly in thirds and sixths?",
  "What exercises can help me develop a more fluid and connected legato bowing in lyrical passages?",
  "How can I practice to achieve a more consistent and controlled martelé stroke in fast passages?",
  "What are some strategies for improving my dynamic range and control on the violin?",
  "How can I develop better finger independence and strength using advanced etudes and exercises?",
  "What are some effective methods for practicing and mastering difficult shifts in high positions?",
  "How can I improve my intonation when playing complex chords and arpeggios?",
  "What exercises can help me maintain evenness and control in my bowing, particularly during fast passages?",
  "How can I practice to achieve a more relaxed and efficient left-hand technique, especially for challenging extensions?",
  "What are some advanced methods for practicing and mastering difficult passages in concertos and solo pieces?",
  "How can I switch from wrist to arm vibrato effectively on the G-string?",
  "What exercises improve finger pressure and intonation in fast double stops?",
  "How do I refine my ricochet technique for smooth string crossings?",
  "What are key strategies to minimize shifting noise in 7th position?",
  "How can I control spiccato in the lower bow for fast passages?",
  "How do I perfect bow distribution for long slurs with dynamic control?",
  "What's the best way to transition between sautillé and detaché bowing?",
  "How can I improve finger preparation for fast arpeggios across strings?",
  "What exercises help with left-hand dexterity in upper-register chromatic runs?",
  "How can I refine flying staccato at the frog for virtuoso passages?",
  "How do I stabilize octave double stops during rapid scales?",
  "What techniques help maintain clarity in expressive saltando passages?",
  "How can I refine intonation for shifts between 5th and 6th positions?",
  "How do I ensure consistent martelé articulation in fast orchestral excerpts?",
  "How can I improve bow articulation at the tip for virtuosic passages?",
  "What exercises strengthen the pinky for control in 4th and 5th positions?",
  "How do I synchronize finger stroke and bow speed in sautillé?",
  "How can I cleanly execute grace notes during large string crossings?",
  "What methods stabilize my hand frame for double-stop 10ths in high positions?",
  "How can I ensure even vibrato across all fingers in slow lyrical passages?",
  "My pinky vibrato is weak and lacks control. How can I improve it?",
  "My detache bowing doesn't produce a full, resonant sound. What can I do to enhance it?",
  "I have pain and tension in my left-hand thumb while shifting. How can I alleviate this?",
  "My bow arm feels tense during fast string crossings. What techniques can help me relax?",
  "My double stops sound out of tune, especially in higher positions. How can I improve their intonation?",
  "I struggle with keeping my wrist relaxed during long practice sessions. Any tips for maintaining relaxation?",
  "My trills are uneven and lack speed. How can I improve their consistency and velocity?",
  "My spiccato feels uncontrolled and inconsistent at faster tempos. How can I refine this technique?",
  "I can't maintain smooth legato when changing strings. What exercises can help me improve this?",
  "My shifting between 3rd and 5th positions is often inaccurate. How can I increase my precision?",
  "I find it hard to maintain consistent dynamics in long phrases. What strategies can I use to improve this?",
  "My bow grip becomes stiff when playing at the frog. How can I maintain flexibility?",
  "I struggle with finger coordination in rapid passages. What exercises can help me improve?",
  "My intonation in octaves gets inconsistent in fast sections. How can I maintain accuracy?",
  "I lose control of my bow when playing soft, slow notes near the tip. How can I improve my control?",
  "Please help me craft a detailed 1-month practice plan focused on scales.",
  "How can I convey deeper emotional nuances in my interpretation of Romantic violin pieces?",
  "What are some methods to bring out inner voices in polyphonic violin works?",
  "How can I develop nuanced bow control to achieve a wider range of tonal colors?",
  "What practice strategies improve my ability to shape and interpret musical phrases?",
  "What are effective ways to communicate the narrative or emotion inherent in a piece?",
  "How can I incorporate rubato to enhance expression without disrupting the overall tempo?",
  "How can articulation and bowing techniques shape the character and style of a piece?",
  "How do I cultivate a personal interpretive voice while honoring the composer's work?",
];
