import axios from 'axios';
// import store from '../store';
// import { setIsNotFound } from '../actions/pages';

const tmpToken = ''//'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcmNvbXVzaWMuaW8iLCJ1aWQiOjEwMTMsImlhdCI6MTY5MTk1NjEyMiwiZXhwIjoyMDUxOTU2MTIyfQ.d_rEMV1n7EOztkXThfoVvYcvGlOMK7EwTfMXblWZc0CCFKCpsm2F2upY9qvFWbd3-5MzCenvb20RpiOk8hvvww';

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken') || tmpToken;

    console.log(config.headers, 'config.headers1');

    config.headers = {
        'content-type': 'application/json',
        'Authorization': (token) ? 'Bearer ' + token : '',
        ...config.headers
    }

    console.log(config.headers, 'config.headers2')

    return config;
});

axios.interceptors.response.use(
    response => response, err => {
    console.log(err, 'errrr');

        // if(err.response?.status === 401 && window.localStorage.getItem('token')) {
        //     window.localStorage.clear();
        //     window.location.reload();
        //     return err;
        // }
        
        // if(err.response.status === 404) {
        //     store.dispatch(setIsNotFound({isNotFound: true}));
        // }

    return Promise.reject(err.response);
});


export const baseURL = (process.env.NODE_ENV == 'production') ? '' : 'https://arcomusic.io';

const api = baseURL + '';
const usersAPI = api + '/users';
const usersAPIOld = baseURL + '/api/v1/entity/users/';
const schoolsAPI = api + '/schools'

const entityApi = api + '/entity/all';
const singleEntityApi = api + '/api/v1';
const foldersAPI = singleEntityApi + '/folders';
const foldersAPIOld = singleEntityApi + '/entity/folders';
const paylistsAPI = baseURL + '/api/v1/entity/playlists';
const paylistsHasItemsAPI = singleEntityApi + '/entity/playlists_has_items';
const accountsAPI = api + '/accounts';
const etudesAPI = api + '/entity/etudes';
const tagsAPI = api + '/entity/tags';
const mediaAPI = api + '/media';
const composersAPI = api + '/api/composers';
const etudesHasTagsAPI = api + '/etudes_has_tags';
const tagsHasMediaAPI = api + '/tags_has_media';
const uploadsAPI = api + '/entity/uploads';


// USERS API
export const loginUser = (payload) => axios.post(usersAPI + "login", payload);
// export const getUser = () => axios.get(usersAPI + "me");
// export const checkAuth = () => axios.get(usersAPI + "check");
// export const checkUserExist = (payload) => axios.post(usersAPI + "exist", payload);


// SCHOOLS API
export const findSchools = (name, page, limit) => {
    const params = new URLSearchParams({
        name,
        page,
        limit
    });

    removeNullsAndUndefined(params);
    return axios.get(schoolsAPI + 'search'+ '?' + params.toString());
}



export const removeNullsAndUndefined = (params) => {
    let keysForDel = [];
    params.forEach((value, key) => {
        if (value === "null" || value === "undefined" ) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });
}

// PLAYLISTS

export const addEtudeToPlaylist = (payload) => axios.post(paylistsHasItemsAPI, payload);
export const createPlaylist = (folderId, payload) => axios.post(foldersAPI + '/' + folderId + '/playlists', payload);
export const deletePlaylist = (folderId, playlistId) => axios.delete(foldersAPI + '/' + folderId + '/playlists' + '/' + playlistId);
export const processPlaylist = (folderId, playlistId, action) => axios.post(foldersAPI + '/' + folderId + '/playlists' + '/' + playlistId + '/' + action);
export const updatePlaylist = (folderId, playlistId, payload) => axios.put(foldersAPI + '/' + folderId + '/playlists' + '/' + playlistId, payload);
export const copyPlaylistItem = (itemId, playlistId) => axios.post(baseURL + '/api/v1/playlists/items/copy/' + itemId + '/' + playlistId);
export const deletePlaylistItem = (itemId, nId = 1, playlistId) => axios.delete(baseURL + '/api/v1/playlists/items/' + itemId + '/' + nId + '/' + playlistId);
export const movePlaylistItem = (itemId, playlistId) => axios.post(baseURL + '/api/v1/playlists/items/move/' + itemId + '/' + playlistId);
export const duplicatePlaylistItem = (itemId, nId = 1, playlistId) => axios.post(baseURL + '/api/v1/playlists/items/dup/' + itemId + '/' + nId + '/' + playlistId);
export const updatePlaylistItem = (payload) => axios.put(paylistsHasItemsAPI, payload);

export const renamePlaylist = (payload) => axios.put(paylistsAPI, payload); 
export const duplicatePlaylist = (folderId, playlistId) => axios.post(foldersAPI + '/' + folderId + '/playlists' + '/' + playlistId + '/dup' );
export const movePlaylist = (folderId, playlistId) => axios.post(foldersAPI + '/' + folderId + '/playlists' + '/' + playlistId + '/move');
export const copyPlaylist = (folderId, playlistId) => axios.post(foldersAPI + '/' + folderId + '/playlists' + '/' + playlistId + '/copy');
export const addToPlaylist = (payload) => axios.post(paylistsHasItemsAPI, payload); 

// ETUDES

export const getEtudeHasTags = () => axios.get(baseURL + '/entity/all/etudes_has_tags');
export const getEtudes = () => axios.get(baseURL + '/entity/all/etudes');

// TAGS
export const getAllTags = () => axios.get(baseURL + '/entity/all/tags');
export const getTagsHasMedia = () => axios.get(baseURL + '/entity/all/tags_has_media');

// MEDIA

export const getAllMedia = () => axios.get(baseURL + '/entity/all/media');

// FOLDERS

export const createFolder = (payload) => axios.post(foldersAPI, payload);
export const deleteFolder = (folderId) => axios.delete(foldersAPI + '/' + folderId);
export const renameFolder = (payload) => axios.put(foldersAPIOld, payload);



// export const addEtudeToPlaylist = (payload) => axios.post(paylistsHasItemsAPI, payload);
// export const addEtudeToPlaylist = (payload) => axios.post(paylistsHasItemsAPI, payload);
// export const addEtudeToPlaylist = (payload) => axios.post(paylistsHasItemsAPI, payload);
// export const addEtudeToPlaylist = (payload) => axios.post(paylistsHasItemsAPI, payload);

// COMPOSERS

export const getAllComposers = () => axios.get(composersAPI);

// UPLOAD FILE
export const getAllUploads = () => axios.get(baseURL + '/entity/all/uploads');
export const uploadFile = (payload, headers) => axios.post(baseURL + '/api/parse/upload-pdf', payload, { headers });
export const removeUpload = (payload) => axios.post(baseURL + '/api/parse/remove-upload', payload);
export const updateUploadedFile = (id, payload) => axios.put(baseURL + '/api/parse/' + id, payload);

// USERS
export const updateUserInfo = (payload) => axios.put(usersAPIOld, payload);
export const forgotPassword = (email) => axios.post(baseURL + '/api/email/forgot', { email });
export const changePassword = (payload) => axios.post(baseURL + '/api/email/change-pass', payload);
export const login = (payload) => axios.post(baseURL + '/auth/login', payload);
export const signup = (payload) => axios.post(baseURL + '/auth/login/register', payload);
export const authGoogle = (payload) => axios.post(baseURL + '/auth/login/google', payload);
export const getMe = async () => {
    const data = localStorage.getItem('accessToken') ? parseJwt(localStorage.getItem('accessToken')) : null;

    if(!data)
        return false;

    return await axios.get(baseURL + '/api/v1/accounts/' + data.uid)
}

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const processTags = async () => {
    
}

export const leaveFeedback = (payload) => axios.post(baseURL + '/api/email/send', payload);


// send form from home page (contect us)
export const contact = (payload) => axios.post(usersAPI, payload);


export const createChat = (payload) =>
  axios.post(baseURL + "/api/v1/chat", payload);

export const listChats = () => axios.get(baseURL + "/api/v1/chat");

export const patchChat = (id, payload) =>
  axios.patch(baseURL + "/api/v1/chat/" + id, payload);

export const deleteChat = (id) =>
  axios.delete(baseURL + "/api/v1/chat/" + id, {});

export const listMessages = (chatId) =>
  axios.get(baseURL + "/api/v1/message/" + chatId);

export const sendUserMessage = async (payload, signal) => {
  const token = localStorage.getItem("accessToken") || tmpToken;
  return fetch(`${baseURL}/api/v1/message`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    signal,
  });
};