import AssistantProvider from "./AssistantProvider";
import AssistantWrapper from "./AssistantWrapper";

const Assistant = () => {
  return (
    <AssistantProvider>
      <AssistantWrapper />
    </AssistantProvider>
  );
};

export default Assistant;
