import React, { useState, useEffect, useRef, forwardRef } from "react";
import { FiMenu, FiMaximize2, FiMinimize2, FiX, FiEdit } from "react-icons/fi"; // Added FiEdit
import "./ChatPopup.scss";
import { MessageList } from "../MessageList/MessageList";
import { ChatInput } from "../ChatInput/ChatInput";
import { useAssistant } from "../AssistantProvider";
import { AssistantStream } from "openai/lib/AssistantStream";
import { APIUserAbortError } from "openai/error.mjs";
import { getRandomQuestions } from "../../../utils/getRandonQuestions";
import { instructionToken } from "../../../config";
import {
  createChat,
  listMessages,
  sendUserMessage,
} from "../../../service/requests";

export const ChatPopup = forwardRef(({ isOpen, onClose }, ref) => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const messagesEndRef = useRef(null);
  const inputAreaRef = useRef(null);
  const abortControllerRef = useRef(null);
  const isNewChat = useRef(false);
  const isChatChange = useRef(false);

  const {
    toggleConversations,
    selectedConversation,
    onSelectConversation,
    onNewConversation,
    onNewMessage,
  } = useAssistant();

  // Function to initialize the chat with welcome message
  const initializeChat = () => {
    const randomQuestions = getRandomQuestions(5);
    const data = {
      role: "assistant",
      content: `🎻 Welcome! I'm Professor Arco AI, your violin mentor, trained with the most comprehensive collection of violin knowledge ever assembled, drawing from the greatest minds in pedagogy. Whether refining technique, enhancing musicality, or improving practice habits, I'm here to help. Let's make your journey productive and enjoyable! Try asking me one of these questions:`,
      sampleQuestions: randomQuestions,
    };
    setWelcomeMessage([data]);
  };

  // fetch messages on chat change, skipping for newly created chats
  useEffect(() => {
    console.log({selectedConversation, check: !isNewChat.current})
    if (selectedConversation && !isNewChat.current) {
      setMessages([]);
      isChatChange.current = true;
      handleCancel();
      setInputValue("");
      setLoading(true);
      listMessages(selectedConversation)
        .then(({ data }) => {
          isChatChange.current = false;
          setMessages(data.messages.reverse());
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log("else run!")
      isNewChat.current =  false;
    }
    initializeChat();
  }, [selectedConversation]);

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
  }, [messages]);

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsTyping(false);
    }
  };

  const handleQuestionClick = (question) => {
    setInputValue(question);
    setTimeout(() => {
      if (inputAreaRef.current) {
        inputAreaRef.current.focus();
      }
    }, 0);
  };

  const toggleView = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleNewChat = () => {
    if (!isOpen) return;
    createNewChat()
      .then((chat) => {
        onSelectConversation(chat.chat_id);
      })
      .catch((errr) => {
        console.log(errr);
      });
  };

  const createNewChat = async () => {
    const {
      data: { chat },
    } = await createChat({ title: "New Chat" });
    onNewConversation(chat);
    return chat;
  };

  const sendMessage = async (text, chat_id) => {
    const modifiedContent = `${text} ${instructionToken}`;

    setIsTyping(true);

    try {
      if (abortControllerRef.current) {
        // Cancel any previous requests
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      const response = await sendUserMessage(
        {
          message: modifiedContent,
          chat_id: chat_id || selectedConversation,
        },
        abortControllerRef.current.signal
      );

      if (!response.ok) {
        // Log and throw an error for non-200 responses
        const errorMessage = `Error: ${response.status} ${response.statusText}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      } else {
        const stream = AssistantStream.fromReadableStream(response.body);
        handleReadableStream(stream);
        await stream.done();
      }
    } catch (error) {
      console.log("Error in sendMessage:", error);
      if (isChatChange.current) return;
      abortControllerRef.current = null;
      if (error.name === "AbortError" || error instanceof APIUserAbortError) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "system", content: "AI response was canceled." },
        ]);
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "system",
            content: "An error occurred while processing your request.",
          },
        ]);
      }
    } finally {
      setIsTyping(false);
    }
  };

  const handleSendMessage = async (userInput) => {
    if (!userInput.trim()) return;

    let chatId = undefined;
    if (!selectedConversation) {
      const chat = await createNewChat();
      chatId = chat.chat_id;
      isNewChat.current = true;
      onSelectConversation(chatId);
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: userInput },
    ]);

    sendMessage(userInput, chatId);
  };

  /* Stream Event Handlers */

  // textCreated - create new assistant message
  const handleTextCreated = () => {
    appendMessage("assistant", "");
  };

  // textDelta - append text to last assistant message
  const handleTextDelta = (delta) => {
    if (delta.value != null) {
      appendToLastMessage(delta.value);
    }
    if (delta.annotations != null) {
      annotateLastMessage(delta.annotations);
    }
    // sort updated chat on top
    onNewMessage();
  };

  const handleRunCompleted = () => {
    setIsTyping(false);
    abortControllerRef.current = null;
  };

  const handleRunFailed = () => {
    setIsTyping(false);
    abortControllerRef.current = null;
  };

  const handleReadableStream = (stream) => {
    stream.on("textCreated", handleTextCreated);
    stream.on("textDelta", handleTextDelta);

    const eventHandler = (event) => {
      if (event.event === "thread.run.completed") handleRunCompleted();
      if (event.event === "thread.run.cancelled") handleRunFailed();
      if (event.event === "thread.run.failed") handleRunFailed();
      if (event.event === "thread.run.expired") handleRunFailed();
    };

    stream.on("event", eventHandler);

    // Clean up listeners on abort
    abortControllerRef.current.signal.addEventListener("abort", () => {
      console.log("Request aborted, cleaning up stream...");
      stream.off("textCreated", handleTextCreated);
      stream.off("textDelta", handleTextDelta);
      stream.off("event", eventHandler);
      if (stream.controller) {
        stream.controller.abort();
      }
    });
  };

  const appendToLastMessage = (content) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
        content: lastMessage.content + content,
      };
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
  };

  const appendMessage = (role, text) => {
    setMessages((prevMessages) => [...prevMessages, { role, content: text }]);
  };

  const annotateLastMessage = (annotations) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
      };
      annotations.forEach((annotation) => {
        if (annotation.type === "file_path") {
          updatedLastMessage.content = updatedLastMessage.content.replaceAll(
            annotation.text,
            `/api/files/${annotation.file_path.file_id}`
          );
        }
      });
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
  };

  if (!isOpen) return null;

  return (
    <>
      <div
        className={`chat-popup ${isOpen ? "open" : ""} ${
          isExpanded ? "expanded" : ""
        }`}
      >
        <div className="chat-header">
          <div className="header-left-buttons">
            <button
              className="hamburger-button"
              onClick={toggleConversations}
              aria-label="Toggle Conversation History"
            >
              <FiMenu size={20} />
            </button>
            {/* New Chat Button */}
            <button
              className="new-chat-button"
              onClick={handleNewChat}
              aria-label="Start New Chat"
            >
              <FiEdit size={20} />
            </button>
          </div>
          <h3>Professor ArcoAI</h3>
          <div className="header-buttons">
            <button
              className="toggle-button"
              onClick={toggleView}
              aria-label={
                isExpanded ? "Collapse Chat Sidebar" : "Expand Chat Sidebar"
              }
              title={
                isExpanded ? "Collapse Chat Sidebar" : "Expand Chat Sidebar"
              }
            >
              {isExpanded ? (
                <FiMinimize2 size={20} />
              ) : (
                <FiMaximize2 size={20} />
              )}
            </button>
            <button
              className="close-button"
              onClick={onClose}
              aria-label="Close Chat"
            >
              <FiX size={20} />
            </button>
          </div>
        </div>
        <MessageList
          messages={
            loading
              ? [
                  {
                    role: "system",
                    content: "Loading...",
                  },
                ]
              : (messages?.length > 0 ? messages : welcomeMessage) || []
          }
          isTyping={isTyping}
          loading={loading}
          onSampleQuestionClick={handleQuestionClick}
        />
        <div ref={messagesEndRef} />
        <ChatInput
          ref={inputAreaRef}
          onSendMessage={handleSendMessage}
          onCancel={handleCancel}
          isTyping={isTyping}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
    </>
  );
});

export default ChatPopup;
