import React from "react";
import "./AssistantIcon.scss";
import chatBoxIcon from "../../../assets/ChatBoxIcon.png"; // Import the PNG icon

export const AssistantIcon = ({ onClick }) => {
  return (
    <div className="assistant-icon" onClick={onClick}>
      <img src={chatBoxIcon} alt="Assistant Icon" /> {/* Use the PNG icon */}
    </div>
  );
};
