import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { listChats } from "../../service/requests";

const AssistantContext = createContext(null);

const AssistantProvider = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenConversations, setIsOpenConversations] = useState(false);

  const onSelectConversation = (conv) => setSelectedConversation(conv);

  const onNewConversation = (conv) => setConversations((old) => [conv, ...old]);

  const onNewMessage = useCallback(() => {
    setConversations((prev) => {
      if (prev[0]?.chat_id !== selectedConversation) {

        const updatedItem = prev.find(
          (c) => c.chat_id === selectedConversation
        );
        const otherItems = prev.filter(
          (c) => c.chat_id !== selectedConversation
        );

        const updatedItemWithTimestamp = {
          ...updatedItem,
          updatedAt: new Date().toISOString(),
        };

        const sorted = [
          ...(updatedItem ? [updatedItemWithTimestamp] : []),
          ...otherItems,
        ];

        return sorted;
      }
      return prev;
    });
  }, [selectedConversation, setConversations]);

  const closeConversations = () => setIsOpenConversations(false);
  
  const toggleConversations = () => setIsOpenConversations((prev) => !prev);

  const onRenameConversation = (id, title) => {
    setConversations((prevList) => {
      const updatedList = prevList.map((conversation) =>
        conversation.chat_id === id ? { ...conversation, title } : conversation
      );
      return updatedList;
    });
  };

  const onDeleteConversation = (id) => {
    setConversations((prevList) => {
      const updatedList = prevList.filter(
        (conversation) => conversation.chat_id !== id
      );
      if (id === selectedConversation) {
        setSelectedConversation(null);
      }
      return updatedList;
    });
  };

  useEffect(() => {
    listChats()
      .then((res) => {
        setConversations(res.data.chats);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AssistantContext.Provider
      value={{
        conversations,
        selectedConversation,
        isPopupOpen,
        setIsPopupOpen,
        onSelectConversation,
        isOpenConversations,
        closeConversations,
        onRenameConversation,
        onDeleteConversation,
        toggleConversations,
        onNewConversation,
        onNewMessage,
      }}
    >
      {children}
    </AssistantContext.Provider>
  );
};

export const useAssistant = () => {
  const data = useContext(AssistantContext);

  if (!data) throw new Error("Wrap useAssistant inside provider!");

  return data;
};

export default AssistantProvider;
