import React, { useEffect, useState } from "react";
import { FiX, FiEdit2, FiTrash2 } from "react-icons/fi";
import "./ConversationHistory.scss";
import { format, isToday, isYesterday, parseISO, toDate } from "date-fns";
import { useAssistant } from "../AssistantProvider";
import { patchChat, deleteChat } from "../../../service/requests";

const formatDateHeading = (date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    return format(date, "MMMM dd");
  }
};

const ConversationHistory = () => {
  const [editingId, setEditingId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [groupedConversations, setGroupedConversations] = useState([]);

  const {
    conversations,
    onSelectConversation,
    closeConversations,
    isOpenConversations,
    selectedConversation,
    onRenameConversation,
    onDeleteConversation,
  } = useAssistant();

  useEffect(() => {
    const groupConversationsByDate = (conversations) => {
      const grouped = conversations.reduce((acc, conversation) => {
        const date = parseISO(conversation.updatedAt);
        const heading = formatDateHeading(date);
        if (!acc[heading]) {
          acc[heading] = [];
        }
        acc[heading].push(conversation);
        acc[heading].sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
        return acc;
      }, {});

      return Object.entries(grouped).sort((a, b) => {
        if (a[0] === "Today") return -1;
        if (b[0] === "Today") return 1;
        if (a[0] === "Yesterday") return -1;
        if (b[0] === "Yesterday") return 1;
        return new Date(b[0]) - new Date(a[0]);
      });
    };
    setGroupedConversations(groupConversationsByDate(conversations));
  }, [conversations]);

  const handleRenameClick = (id, title) => {
    setEditingId(id);
    setEditingTitle(title);
  };

  const handleRenameSubmit = (id) => {
    patchChat(id, { title: editingTitle })
      .then(({ data }) => {
        onRenameConversation(id, data.chat.title);
        console.log(data);
        setEditingId(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteClick = (id) => {
    setDeleteConfirmId(id);
  };

  const handleDeleteConfirm = () => {
    if (deleteConfirmId) {
      deleteChat(deleteConfirmId)
        .then(({ data }) => {
          console.log(data);
          onDeleteConversation(deleteConfirmId);
          setDeleteConfirmId(null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div
      className={`conversation-history ${isOpenConversations ? "open" : ""}`}
    >
      <div className="conversation-history-header">
        <h4>Conversation History</h4>
        <button
          className="close-history-button"
          onClick={closeConversations}
          aria-label="Close History"
        >
          <FiX size={20} />
        </button>
      </div>
      <div className="conversation-list-container">
        {groupedConversations.map(([date, convos]) => (
          <div key={date} className="conversation-group">
            <div className="conversation-group-title">{date}</div>
            {convos.map((conversation) => (
              <div
                key={conversation.chat_id}
                className={`conversation-item ${
                  conversation.chat_id === selectedConversation ? "active" : ""
                }`}
                onClick={() => onSelectConversation(conversation.chat_id)}
              >
                {editingId === conversation.chat_id ? (
                  <input
                    type="text"
                    value={editingTitle}
                    onChange={(e) => setEditingTitle(e.target.value)}
                    onBlur={() => handleRenameSubmit(conversation.chat_id)}
                    onKeyPress={(e) =>
                      e.key === "Enter" &&
                      handleRenameSubmit(conversation.chat_id)
                    }
                    autoFocus
                    onClick={(e) => e.stopPropagation()} // Prevent click from bubbling up
                  />
                ) : (
                  <>
                    <span>{conversation.title}</span>
                    <div
                      className="conversation-actions"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        onClick={() =>
                          handleRenameClick(
                            conversation.chat_id,
                            conversation.title
                          )
                        }
                        aria-label="Rename"
                      >
                        <FiEdit2 size={16} />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(conversation.chat_id)}
                        aria-label="Delete"
                      >
                        <FiTrash2 size={16} />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      {deleteConfirmId && (
        <div className="delete-confirm-modal">
          <p>Are you sure you want to delete this conversation?</p>
          <button onClick={handleDeleteConfirm}>Delete</button>
          <button onClick={() => setDeleteConfirmId(null)}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default ConversationHistory;
